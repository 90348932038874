import React from 'react';
import './styles/global.scss';
import './App.css';
import { RecoilRoot } from 'recoil';
import { ConfigProvider } from 'antd';
import { BrowserRouter } from 'react-router-dom';
import Pages from './pages';

function App() {
  return (
    <BrowserRouter>
      <RecoilRoot>
        <ConfigProvider>
          <Pages />
        </ConfigProvider>
      </RecoilRoot>
    </BrowserRouter>
  );
}

export default App;
