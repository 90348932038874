import { useMemo, useState } from 'react';
import AuthApi from 'libs/api/auth';
import AuthService from 'services/Auth';
import { useNavigate, useSearchParams } from 'react-router-dom';
import qs from 'qs';
import Helpers from 'helpers';

const api = new AuthApi();

const useAuth = () => {
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [errors, setErrors] = useState([]);
  let navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const currentParams = Object.fromEntries([...searchParams]);

  const pathToRedirect = useMemo(() => {
    if (currentParams.redirectUrl) {
      const { redirectUrl, ...rest } = currentParams;
      return `${redirectUrl}${rest ? `?${qs.stringify(rest)}` : ''}`;
    }

    return '/';
  }, [currentParams]);

  const handleLogin = async (values) => {
    setLoadingBtn(true);
    try {
      const {
        data: { token }
      } = await api.signIn(values);
      AuthService.setToken(token);
      navigate(pathToRedirect);
    } catch (e) {
      let errors = Helpers.getErrorsFromResponse(e);
      if (!errors.length) {
        errors = ['Something went wrong!'];
      }
      setErrors(errors);
    } finally {
      setLoadingBtn(false);
    }
  };

  return {
    handleLogin,
    loadingBtn,
    errors
  };
};

export default useAuth;
