import { Form, Input, Row, Col, Spin } from 'antd';
import styles from './style.module.scss';
import { CheckOutlined } from '@ant-design/icons';
import useUsers from '../useUsers';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import PageHeader from 'components/pageHeader';
import Button from 'components/button';

const Edit = ({ isNew }) => {
  const { id } = useParams();
  const { form, handleCreateOrUpdate, loading } = useUsers(false, id);

  const onFinish = async (values) => {
    await handleCreateOrUpdate(values, id);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const handleCreate = () => {
    form.submit();
  };

  const title = isNew ? 'Նոր օգտատեր' : 'Խմբագրել օգտատիրոջը';

  return (
    <div>
      <PageHeader title={title} />
      <div className={styles.content}>
        <Spin spinning={loading}>
          <Form
            form={form}
            name="basic"
            wrapperCol={{
              span: 24
            }}
            initialValues={{
              title: ''
            }}
            layout={'vertical'}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Row gutter={24}>
              <Col span={8}>
                <Form.Item label={'Անուն'} name="title">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label={'Ազգանուն'} name="title">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label={'Համար'} name="title">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label={'Էլ․ հասցե'} name="title">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label={'Ռեստորաններ'} name="title">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={8} />
              <Col span={8}>
                <Form.Item label={'Գախտնաբառ'} name="title">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label={'Կրկնել գախտնաբառը'} name="title">
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <br />
        </Spin>
        <Button
          onClick={handleCreate}
          width={'150px'}
          big
          icon={<CheckOutlined />}
          loading={loading}
        >
          Պահպանել
        </Button>
      </div>
    </div>
  );
};

Edit.propTypes = {
  isNew: PropTypes.bool
};

export default Edit;
