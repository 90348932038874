import axios from 'axios';
import AuthService from 'services/Auth';
// import UIHelper from 'helpers/UIHelper';

class Api {
  constructor(resource = '') {
    const apiUrl = process.env.REACT_APP_API_URL;
    this.url = `${apiUrl}/${resource}`;
  }

  getExcel = (params) => {
    return axios({
      url: `${this.url.slice(0, -1)}.xlsx`,
      method: 'get',
      data: params.data,
      params: params.params,
      responseType: 'blob', // important
      headers: {
        'Content-Type': 'application/json',
        ...(params?.headers || {}),
        'auth-token': AuthService.getToken()
      }
    });
  };

  list = (params) => {
    return this.buildQuery('get', params);
  };

  get = (id, params = {}) => {
    return this.buildQuery('get', params, id);
  };

  create = (params) => {
    return this.buildQuery('post', params);
  };

  put = (id, params) => {
    return this.buildQuery('put', params, id);
  };

  destroy = (id) => {
    return this.buildQuery('delete', {}, id);
  };

  buildQuery = (method, params = { props: {} }, prefix) => {
    const props = params?.props || {};
    return axios({
      url: this.getFullUrl(prefix),
      method,
      timeout: 10000 * 6,
      data: params.data,
      params: params.params,
      responseType: params.responseType, // important
      ...props,
      headers: {
        'Content-Type': 'application/json',
        'auth-token': AuthService.getToken(),
        ...(params?.headers || {})
      }
    });
  };

  getFullUrl = (prefix) => {
    return `${this.url}${prefix ? `${prefix}` : ''}`;
  };
}

export default Api;
