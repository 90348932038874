import CompaniesPages from './subPages/company';
import UsersPages from './subPages/users';

const routes = [
  {
    path: 'companies/*',
    canUse: [],
    Component: CompaniesPages
  },
  {
    path: 'users/*',
    canUse: [],
    Component: UsersPages
  }
];

export default routes;
