import React from 'react';
import PropTypes from 'prop-types';
import styles from './style.module.scss';

const PageHeader = ({ title, children }) => {
  return (
    <div className={styles.header}>
      <div className={styles.title}>{title}</div>
      <div className={styles.rightSide}>{children}</div>
    </div>
  );
};

PageHeader.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node
};

export default PageHeader;
