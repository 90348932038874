import React from 'react';
import { Alert, Col, Divider, Row } from 'antd';
import Text from 'antd/es/typography/Text';
import uuid from 'react-uuid';
import PropTypes from 'prop-types';

const ShowError = ({ errors = [], type = '' }) => {
  if (!errors) return null;
  if (!errors.length) return null;

  if (type === 'alert') {
    return (
      <>
        <Row gutter={[8, 8]}>
          {errors.map((i) => (
            <Col key={uuid()} span={24}>
              <Alert message={i} type="warning" />
            </Col>
          ))}
        </Row>
        <Divider />
      </>
    );
  }

  return (
    <Row>
      {errors.map((i) => (
        <Col key={uuid()} span={24}>
          <Text type="danger">{i}</Text>
        </Col>
      ))}
    </Row>
  );
};

ShowError.propTypes = {
  errors: PropTypes.array,
  type: PropTypes.string
};

export default ShowError;
