import { Route, Routes } from 'react-router-dom';
import PropTypes from 'prop-types';

const SetRoutes = ({ routes }) => {
  return (
    <Routes>
      {routes.map((i) => {
        const { path, Component, props = {} } = i;
        return <Route key={path} path={path} element={<Component {...props} />} />;
      })}
    </Routes>
  );
};

SetRoutes.defaultProps = {
  routes: []
};

SetRoutes.propTypes = {
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      Component: PropTypes.elementType.isRequired,
      props: PropTypes.object
    })
  )
};

export default SetRoutes;
