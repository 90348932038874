import PageHeader from 'components/pageHeader';
import { Col, Divider, Row, Table } from 'antd';
import { Link } from 'react-router-dom';
import Button from 'components/button';
import { PlusOutlined } from '@ant-design/icons';
import useUsers from '../useUsers';

const MainPage = () => {
  const { columns, data } = useUsers();

  return (
    <>
      <PageHeader title={'Օգտատերներ'}>
        <Row gutter={16}>
          <Col>
            <Link to={'new'}>
              <Button big icon={<PlusOutlined />}>
                Ստեխծել նոր օգտատեր
              </Button>
            </Link>
          </Col>
        </Row>
      </PageHeader>
      <Divider />
      <Table
        columns={columns}
        dataSource={data.chains}
        pagination={data.pagination}
        scroll={{ x: 1000 }}
        // loading={loading}
        // onChange={onChangeTableFilter}
      />
    </>
  );
};

export default MainPage;
