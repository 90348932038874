import React from 'react';
import styles from './style.module.scss';
import Footer from 'components/footer';
import LoginForm from './form';
import useAuth from './useAuth';

const LoginPage = () => {
  const { handleLogin, loadingBtn, errors } = useAuth();

  const onSubmit = async (values) => {
    await handleLogin(values);
  };

  return (
    <div className={styles.loginPageContainer}>
      <div className={styles.loginPageContainer_content}>
        <LoginForm errors={errors} loadingBtn={loadingBtn} onSubmit={onSubmit} />
      </div>
      <Footer />
    </div>
  );
};

export default LoginPage;
