import _ from 'lodash';
// import fileDownload from 'js-file-download';
// import axios from 'axios';

class Helpers {
  static addSearchProps() {
    return {
      optionFilterProp: 'title',
      showSearch: true,
      filterOption: (input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    };
  }

  static downloadFileFromUri(uri, name) {
    const link = document.createElement('a');
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  static isEmptyObject(obj) {
    return Object.values(obj).every((value) => {
      return value === '';
    });
  }

  static downloadImageFromUrl(e) {
    e.stopPropagation();
    e.preventDefault();
    fetch(e.target.href, {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': '*'
      },
      mode: 'no-cors'
    })
      .then((res) => {
        return res.blob();
      })
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'image.png'); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      // .then((response) => {
      //   response.arrayBuffer().then(function (buffer) {
      //     const url = window.URL.createObjectURL(new Blob([buffer]));
      //     const link = document.createElement('a');
      //     link.href = url;
      //     link.setAttribute('download', 'image.png'); //or any other extension
      //     document.body.appendChild(link);
      //     link.click();
      //   });
      // })
      .catch((err) => {
        console.log(err);
      });
  }

  static downloadFile(response, name) {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', name); //or any other extension
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Download complete remove element
    window.URL.revokeObjectURL(url); // Release blob object
  }

  static getErrorsFromResponse(e) {
    const errorData = e?.response?.data || {};
    return Object.values(errorData).reduce((sum, i) => {
      return [...sum, ...i];
    }, []);
  }

  static getFilePath = (filePath) => {
    return `${window.origin}${filePath}`;
  };

  static hideTicksByCount = (data, tick) => {
    const ratio = data.length / 20;
    if (ratio <= 1) {
      return tick;
    }
    const findIndex = _.findIndex(data, function (o) {
      return o['Periodo'] === tick;
    });

    if (findIndex % Math.round(ratio) === 0) {
      return tick;
    }
    return false;
  };

  static numberWithCommas(num, prefix = '') {
    if (0 == num) {
      return '0,0' + prefix;
    }
    if (!num) return '';

    const newNum = num.toString().replace('.', ',');

    return newNum?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, '.') + prefix;
  }

  static changeDotToComa(num) {
    return num?.toString().replaceAll('.', ',');
  }

  static getMaxValuFromArrayOfObject(data, param) {
    if (_.maxBy(data, param)) {
      return _.maxBy(data, param)[param];
    }
    return 0;
  }

  // static axiosDownload(url, filename) {
  //   axios
  //     .get(url, {
  //       responseType: 'blob'
  //     })
  //     .then((res) => {
  //       fileDownload(res.data, filename);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }
}

export default Helpers;
