import { Layout as AntLayout, Menu, theme } from 'antd';
import { useState } from 'react';
import { LoginContainer } from './styled';
import Footer from 'components/footer';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import pages from '../../constants/pages';

const { Header, Content, Sider } = AntLayout;

const Layout = ({ children }) => {
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer }
  } = theme.useToken();

  const onClick = ({ key }) => {
    console.log('click ', key);
    navigate(key);
  };

  return (
    <AntLayout
      style={{
        minHeight: '100vh'
      }}
    >
      <Sider collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
        <LoginContainer collapsed={collapsed}>Logo</LoginContainer>
        <Menu
          onClick={onClick}
          theme="dark"
          defaultSelectedKeys={['4']}
          mode="inline"
          items={pages}
        />
      </Sider>
      <AntLayout>
        <Header
          style={{
            padding: 0,
            background: colorBgContainer
          }}
        />
        <Content
          style={{
            margin: '16px'
          }}
        >
          <div
            style={{
              padding: 24,
              minHeight: '100%',
              background: colorBgContainer
            }}
          >
            {children}
          </div>
        </Content>
        <Footer />
      </AntLayout>
    </AntLayout>
  );
};

Layout.propTypes = {
  children: PropTypes.node
};
export default Layout;
