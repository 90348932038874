import React from 'react';
import { Row, Col, Typography } from 'antd';
import { Footer as AntFooter } from 'antd/es/layout/layout';
import styles from './style.module.scss';
import { DateTime } from 'luxon';

const Footer = () => {
  const year = DateTime.fromJSDate(new Date()).toFormat('yyyy');

  return (
    <AntFooter className={styles.footer}>
      <Row justify={'center'}>
        <Col span={24}>
          <div className={styles.textWrapper}>
            <Typography className={styles.titles}>
              <p>© Copyright {year}</p>
            </Typography>
          </div>
        </Col>
      </Row>
    </AntFooter>
  );
};

export default Footer;
