import { Routes, Route } from 'react-router-dom';
import { paths } from 'constants/pages';
import LoginPage from './login';
import HomePage from './home';
import AdminPage from './admin';

function Pages() {
  return (
    <Routes>
      <Route path={paths.login} element={<LoginPage />} />
      <Route path={paths.home} element={<HomePage />} />
      <Route path={paths.admin} element={<AdminPage />} />
    </Routes>
  );
}

export default Pages;
