import styled, { css } from 'styled-components';

export const LoginContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  margin: 20px;
  padding: 20px;
  border-radius: 10px;

  ${({ collapsed }) =>
    collapsed &&
    css`
      visibility: hidden;
    `}
`;
