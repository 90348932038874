import React from 'react';
import PropTypes from 'prop-types';
import styles from './style.module.scss';
import { Form, Input } from 'antd';
import Button from 'components/button';
import ShowError from 'components/showError';
import { KeyOutlined, MailOutlined } from '@ant-design/icons';

const LoginForm = ({ onSubmit, loadingBtn, errors }) => {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    onSubmit(values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className={styles.formWrapper}>
      <Form
        form={form}
        name="basic"
        initialValues={{
          remember: true
        }}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label={'E-mail'}
          name="email"
          rules={[
            {
              required: true,
              message: 'Insert your email!'
            }
          ]}
        >
          <Input prefix={<MailOutlined />} placeholder={'example@mail.com'} size={'large'} />
        </Form.Item>

        <Form.Item
          label={'Password'}
          name="password"
          rules={[
            {
              required: true,
              message: 'Insert your password!'
            }
          ]}
        >
          <Input.Password prefix={<KeyOutlined />} placeholder={'******'} size={'large'} />
        </Form.Item>
        <ShowError errors={errors} />
        <Form.Item
          wrapperCol={{
            span: 24
          }}
        >
          <Button
            className={styles.button}
            type="primary"
            htmlType="submit"
            size={'large'}
            loading={loadingBtn}
          >
            Login
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  loadingBtn: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired
};

export default LoginForm;
