import { useMemo, useState } from 'react';
import { Button, Col, Form, Row } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

const useCompany = () => {
  const [form] = Form.useForm();
  const { pathname } = useLocation();
  const [loading, setLoading] = useState(false);
  // const navigate = useNavigate();
  // const { id } = useParams();

  const columns = useMemo(() => {
    return [
      {
        title: 'Անուն',
        dataIndex: 'title',
        width: 100,
        sorter: () => {}
      },
      {
        title: '',
        dataIndex: 'id',
        align: 'right',
        width: 120,
        render: (data, row) =>
          (row.can_update !== false || row.can_destroy !== false) && (
            <Row gutter={16} justify={'end'}>
              {row.can_update !== false && (
                <Col>
                  <Link to={`${pathname}/${row.id}/edit`}>
                    <Button type={'secondary'} icon={<EditOutlined />} />
                  </Link>
                </Col>
              )}
              {row.can_destroy !== false && (
                <Col>
                  <Link to={`${pathname}/${row.id}/delete`}>
                    <Button type={'red'} icon={<DeleteOutlined />} />
                  </Link>
                </Col>
              )}
            </Row>
          )
      }
    ];
  }, [pathname]);

  const handleCreateOrUpdate = async () => {
    setLoading(true);
  };

  return {
    columns,
    data: [],
    form,
    handleCreateOrUpdate,
    loading
  };
};

export default useCompany;
