import MainPage from './main';
import Edit from './edit';

const routes = [
  {
    path: '/*',
    Component: MainPage,
    props: {}
  },
  {
    path: '/:id/edit',
    Component: Edit,
    props: {}
  },
  {
    path: '/new',
    Component: Edit,
    props: {
      isNew: true
    }
  }
];

export default routes;
