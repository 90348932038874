import Layout from 'components/layout';
import SetRoutes from 'components/setRoutes';
import routes from './routes';

const AdminPage = () => {
  return (
    <Layout>
      <SetRoutes routes={routes} />
    </Layout>
  );
};

export default AdminPage;
