import { UserOutlined } from '@ant-design/icons';

export const links = {
  admin: '/admin'
};

export const paths = {
  home: '/',
  admin: '/admin/*',
  login: '/login'
};

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label
  };
}

const pages = [
  getItem('Admin', '/', <UserOutlined />, [
    getItem('Օգտատերներ', '/admin/users'),
    getItem('Ռեստորաններ', '/admin/companies'),
    getItem('Permissions', '5')
  ]),
  getItem('Գանձապահ', '/login')
];

export default pages;
